.croppie-container {
  .cr-slider-wrap {
    position: relative;

    &:before {
      content: '-';
      position: absolute;
      left: -25px;
      font-size: 50px;
      line-height: 10px;
      top: 1px;
    }

    &:after {
      content: '+';
      position: absolute;
      right: -30px;
      font-size: 40px;
      line-height: 10px;
      top: 3px;
    }
  }
}
