/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
// @import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
// @import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Swiper CSS */
@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/keyboard';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/scrollbar';
@import 'swiper/scss/zoom';
@import '@ionic/angular/css/ionic-swiper';
@import 'theme/swiper';

@import 'theme/variables';
@import 'theme/curbnturf-variables';

@import 'theme/toast';

/* Custom CSS */
/* replaced scss/bootstrap */

@import 'theme/reboot';
@import 'theme/components';
@import 'theme/croppie';
@import 'theme/fonts';
@import 'theme/cnt-modal-admin';
@import 'theme/navigation';
@import 'theme/scrollbars';
@import 'theme/toggle-switch';
@import 'theme/type';
@import 'theme/badge';

@import 'theme/close';
@import 'theme/dropdown';
@import 'theme/carousel';
@import 'theme/here-maps';

@import 'theme/normalize';
@import 'theme/webflow';
@import 'theme/wf-curbnturf';
@import 'theme/wf-extra';

// Styles for croppie
@import '~croppie/croppie.css';
@import 'theme/dragula';

.to-upper-case {
  text-transform: uppercase;
}

/** CurbNTurf Color Classes **/
.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

div.sentry-error-embed {
  padding-top: 50px;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.ionic-photo-gallery {
  --max-width: 100%;
  --mag-height: 100%;
  --height: 100%;
  --width: 100%;
}

.card-content-ios h3,
.card-content-ios h4,
.card-content-ios h5,
.card-content-ios h6,
ion-card-content h4 {
  font-size: 1.2em !important;
  font-weight: bold !important;
  color: black;
}

ion-card {
  font-family: proxima-nova, sans-serif;
}

ion-card.dashboard {
  font-family: proxima-nova, sans-serif;
  border: 1px solid #d8d6d6;
  box-shadow: none;
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  color: black;

  h4 {
    font-weight: bold !important;
    font-size: 1.3em !important;
  }

  h3 {
    font-weight: bold !important;
    font-size: 1.5em !important;
  }

  ion-card-content p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ion-card-header {
    font-family: rift-soft, sans-serif;
    border-bottom: 1px solid #d8d6d6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  ion-card-title {
    font-size: 1.4em;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .fine-print {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4em;
  }
}

ion-textarea {
  textarea {
    height: 400px;
  }
}

.w-button.trinidad {
  font-family: rift-soft, sans-serif;
  letter-spacing: 1.5px;
  font-size: 16px;
}

.ngx-datatable .datatable-body {
  .datatable-row-wrapper {
    &:hover {
      background-color: #d8d6d6;
    }

    &:nth-child(odd) {
      background-color: #fcd7c5;

      &:hover {
        background-color: #f9b695;
      }
    }
  }
}

ion-item {
  overflow: visible !important;
}

.croppie-container .cr-slider-wrap:before,
.croppie-container .cr-slider-wrap:after {
  content: '';
}

.input-wrapper {
  margin: 0;
}

.cnt-button.upload-image {
  height: 150px;
  width: 150px;
}
