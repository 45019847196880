.swiper-button-next,
.swiper-button-prev {
  background-color: var(--dark-50);
  padding: 30px 20px;
  border-radius: 5px;

  &:after {
    color: var(--white);
  }
}

.swiper {
  .swiper-pagination-bullet {
    background: var(--dark-25);
  }

  .swiper-pagination-bullet-active {
    background: var(--dark);
  }
}
