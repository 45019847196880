.toggle {
  transform-style: preserve-3d;
  transition: transform 250ms ease-in 0s;

  .active-overlay {
    transition: opacity 250ms ease-in 0s;
  }

  .top-line {
    transition: transform 250ms ease-in 0s, width 250ms ease-in 0s;
  }

  .bottom-line {
    transition: transform 250ms ease-in 0s, width 250ms ease-in 0s;
  }

  &.on {
    transition: transform 250ms ease-in 0s;
    transform: translateX(24px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(-90deg);

    .active-overlay {
      transition: opacity 250ms ease-in 0s;
      opacity: 1;
    }

    .top-line {
      transition: transform 250ms ease-in 0s, width 250ms ease-in 0s;
      width: 14px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(2px) translateY(2px) translateZ(0px);
    }

    .bottom-line {
      transition: transform 250ms ease-in 0s, width 250ms ease-in 0s;
      width: 6px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(1px) translateY(-4px) translateZ(0px);
    }
  }
}
