.sb,
body {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--light);

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: var(--light);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 6px;
    border: 3px solid var(--light);
  }
}
