@font-face {
  font-family: 'curbnturf-icons';
  src: url('/fonts/curbnturf-icons.eot?8k0hmg');
  src: url('/fonts/curbnturf-icons.eot?8k0hmg#iefix') format('embedded-opentype'),
    url('/fonts/curbnturf-icons.ttf?8k0hmg') format('truetype'),
    url('/fonts/curbnturf-icons.woff?8k0hmg') format('woff'),
    url('/fonts/curbnturf-icons.svg?8k0hmg#curbnturf-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-cnt-'],
[class*=' icon-cnt-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'curbnturf-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cnt-plus:before {
  content: '\f067';
}
.icon-cnt-minus:before {
  content: '\f068';
}
.icon-cnt-search:before {
  content: '\f002';
}
.icon-cnt-envelope:before {
  content: '\f003';
}
.icon-cnt-check:before {
  content: '\f00c';
}
.icon-cnt-times:before {
  content: '\f00d';
}
// .icon-camera:before {
//   content: '\f030';
// }
.icon-cnt-chevron-left:before {
  content: '\f053';
}
.icon-cnt-chevron-right:before {
  content: '\f054';
}
.icon-cnt-times-circle:before {
  content: '\f057';
}
.icon-cnt-chevron-up:before {
  content: '\f077';
}
.icon-cnt-chevron-down:before {
  content: '\f078';
}
.icon-cnt-twitter:before {
  content: '\f099';
}
.icon-cnt-facebook:before {
  content: '\f09a';
}
.icon-cnt-linkedin:before {
  content: '\f0e1';
}
// .icon-youtube:before {
//   content: '\f16a';
// }
.icon-cnt-instagram:before {
  content: '\f16d';
}
.icon-cnt-google:before {
  content: '\f1a0';
}
.icon-cnt-paper-plane:before {
  content: '\f1d8';
}
// .icon-send:before {
//   content: '\f1d8';
// }
.icon-cnt-share:before {
  content: '\f1e0';
}
.icon-cnt-trash:before {
  content: '\f1f8';
}
.icon-cnt-clone:before {
  content: '\f24d';
}
// .icon-compass:before {
//   content: '\f124';
// }
