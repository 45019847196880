.hidden {
  display: none;
}

.page {
  > section {
    background-color: var(--body-bg);
    margin-top: 72px;
  }
}

a {
  color: var(--color);
}

.alert-danger {
  color: #850000;
  background-color: #ffcccc;
  border-color: #ffb8b8;
  position: relative;
  padding: 7.5px 12.5px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 2.5px;
  text-align: left;
  margin-top: 10px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

button,
.cnt-button {
  background-color: transparent;
  cursor: pointer;

  &.disabled {
    border-color: var(--secondary-50);
    color: var(--secondary-50);
    cursor: default;

    &:active {
      background-color: var(--white);
      border-color: var(--secondary-50);
      color: var(--secondary-50);
    }
  }
}

.rounded-circle {
  border-radius: 50%;
}

// map
.H_ib_body,
.H_ib_content {
  padding: 0 !important;
  margin: 0 !important;
}

// fixes for site exclusive and amenity display
.icon-inner-wrapper {
  .title-wrapper {
    justify-content: left;
    width: 100%;

    .rift-soft-copy {
      max-width: calc(100% - 52px);
      line-height: 1;
    }
  }
}

@media screen and (max-width: 767px) {
  .page {
    > section {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 479px) {
  .page {
    > section {
      margin-top: 72px;
    }
  }
}
