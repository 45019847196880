@import 'caret';

.dropdown-toggle-cnt {
  white-space: nowrap;

  // Generate the caret automatically
  @include caret();
}

// The dropdown menu
.dropdown-menu-cnt {
  position: absolute;
  right: 0;
  z-index: 1000;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 100px;
  padding: 5px 0;
  margin: 1.25px 0 0; // override default ul
  color: var(--secondary);
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid var(--secondary);
  border-radius: 2.5px;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup-cnt {
  .dropdown-menu-cnt {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 1.25px;
  }

  .dropdown-toggle-cnt {
    @include caret(up);
  }
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item-cnt {
  display: block;
  width: 100%; // For `<button>`s
  padding: 5px 10px;
  clear: both;
  font-weight: normal;
  color: var(--primary);
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s

  &:hover {
    color: var(--white);
    text-decoration: none;
    background-color: var(--secondary);
  }

  &.active,
  &:active {
    color: var(--white);
    text-decoration: none;
    background-color: var(--secondary);
  }

  .dropdown-icons-standard {
    height: 32px;
    width: 32px;
    display: block;
    margin: -9px 7px 0 -4px;

    svg {
      height: 32px;
    }
  }

  .dropdown-icons {
    height: 14px;
    width: 14px;
    display: block;
    margin: 2px 16px 0 5px;
  }
}

.dropdown-menu-cnt.show {
  display: block;
}

ngb-typeahead-window.dropdown-menu-cnt.show {
  bottom: auto;
}

.ng-select {
  z-index: auto !important;

  &.ng-select-single {
    .ng-select-container {
      height: 48px;
      padding: 12px 0px;

      &.ng-has-value {
        .ng-placeholder {
          display: none;
        }
      }
    }

    .ng-dropdown-panel {
      background-color: var(--white);
      left: 0px;
      border: 1px solid black;
      border-top: none;
      padding: 0 10px;
      border-radius: 0 0 9px 9px;
      line-height: 3;
    }
  }
}
