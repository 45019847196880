@import './_mapsjs-ui.scss';

.heremap-container {
  .H_logo {
    margin: 0 4px !important;
  }

  .H_ui {
    font-family: proxima-nova, sans-serif;

    .H_ib_body {
      background: var(--white);
      border-radius: 10px;
      padding: 0;

      .H_ib_content {
        margin: 0;
        padding: 0;
      }

      .site-wrapper {
        margin: -4px;

        .site-information {
          height: auto;
          padding: 10px 15px;

          h4,
          h6 {
            text-overflow: ellipsis;
          }
        }
      }

      .H_ib_close {
        padding: 8px;
        font-size: 11.5px;
        border: none;
        border-radius: 0 10px 0 0;
        right: 0;
        top: 0;
        background: var(--secondary-80);
        z-index: 2;

        svg.H_icon {
          fill: var(--white);
        }
      }

      textarea {
        border-width: 2px;
        border-radius: 10px;
        height: 80px;
        font-size: 12px;
        width: 300px;
      }
    }
  }

  .H_scalebar {
    margin-top: 0;
  }

  .H_ib_tail {
    display: none;
  }

  .H_l_middle {
    top: 24%;
  }
}

curbnturf-search-map,
.main-map-div {
  .heremap-container {
    .H_scalebar {
      margin-top: 42px;
    }
  }
}
