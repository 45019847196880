.badge {
  display: inline-block;
  padding: 0.4em 0.55em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2.5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.badge-info {
  color: var(--dark);
  background-color: var(--ion-color-medium);
}

.badge-danger {
  color: var(--white);
  background-color: var(--ion-color-danger);
}

.badge-green {
  color: var(--dark);
  background-color: var(--ion-color-success);
}

.badge-success {
  color: var(--white);
  background-color: var(--ion-color-tertiary);
}

.badge-secondary {
  color: var(--white);
  background-color: var(--secondary);
}

.badge-warning {
  background-color: var(--ion-color-warning);
}
