.cnt-modal-header {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  border-bottom: 1px solid var(--secondary);
  margin-bottom: 12px;
}

.cnt-modal-close {
  position: absolute;
  top: 16px;
  right: 12px;
  font-size: 36px;
  line-height: 24px;
  z-index: 1;
}

.ionic-photo-gallery.photo-modal {
  color: pink;

  &::part(content) {
    width: 100%;
    max-width: 1270px;
    max-height: 546px;
  }
}
