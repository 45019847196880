.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > *:not(div) {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    router-outlet {
      flex-grow: 0;
    }
  }
}

.button {
  &.trinidad.w-button {
    background-color: #fff;
  }

  &.nandor:hover,
  &.trinidad:hover {
    transform: none;
    color: #fff;
    background-color: var(--primary);
  }
}

.learn-more:hover {
  transform: none;
}

.app-button:hover {
  opacity: 0.75;
  transform: none;
}

button,
.cnt-button,
.nav-link {
  outline: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:active,
  &.active {
    path,
    circle,
    line {
      fill: #fff !important;
    }
  }
}

.alert-button.sc-ion-alert-md {
  display: flex;
  height: 36px;
  min-width: 96px;
  padding-right: 18px;
  padding-left: 18px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #454545;
  border-radius: 50vh;
  background-color: #fff;
  font-family: rift-soft, sans-serif;
  color: #454545;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-decoration: none;

  &.active,
  &:active {
    border-color: transparent;
    background-color: #454545;
    color: #fff;
  }

  .alert-button-inner.sc-ion-alert-md {
    justify-content: normal;
    width: inherit;
    height: inherit;
  }
}

.listing-description {
  white-space: pre-wrap;
}

.small-listing-card-boondock,
.small-listing-card-private {
  margin-right: 0;
}

.text-field {
  padding: 24px 12px;
  margin-right: 24px;
}

.w-input[type='number'] {
  padding-right: 7px;
  padding-left: 14px;
}

.text-input-rounded {
  height: 36px;
}

.element-outer {
  .element-inner.currency-rounded-left {
    border-right-width: 1px;
  }

  .element-inner.currency-rounded-right {
    border-left-width: 1px;
  }
}

.dropdown-icon {
  bottom: 12px;
}

.dropdown-toggle {
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  letter-spacing: 1.5px;

  option {
    font-family: rift-soft, sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient.
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference

  */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2016%2016%22%20style%3D%22enable-background%3Anew%200%200%2016%2016%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.stroke0%7Bfill%3Anone%3Bstroke%3A%23454545%3Bstroke-width%3A2%3Bstroke-linecap%3Around%3B%7D%3C%2Fstyle%3E%3Cg%20id%3D%22icn_back%22%20transform%3D%22translate%2815.445%201.412%29%20rotate%2890%29%22%3E%3Cline%20id%3D%22Line_17%22%20class%3D%22stroke0%22%20x1%3D%222.6%22%20y1%3D%2214.5%22%20x2%3D%2210.6%22%20y2%3D%227.5%22%3E%3C%2Fline%3E%3Cpath%20id%3D%22Path_44%22%20class%3D%22stroke0%22%20d%3D%22M2.7%2C0.4l7.9%2C7%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 12px, 100%;

  &::-ms-expand {
    display: none;
  }
}

.status-block {
  cursor: pointer;

  &.selected {
    padding-bottom: 10px;
    border-bottom: 2px solid #e3530d;
  }
}

.vg {
  z-index: 25;
}

.nav-link {
  &.trinidad {
    &:hover,
    &.w--current {
      border-style: solid;
      border-width: 0 0 3px;
      border-color: #000 #000 #e3530d;
      font-weight: 700;
    }
  }
}

.video-wrapper {
  grid-column-start: span 2;
}

.reservation-booking-section {
  margin-top: 0;
}

.map-card-wrapper {
  flex-wrap: wrap;

  .listing-card,
  .small-listing-card-private,
  .small-listing-card-boondock {
    margin-right: 17px;
    margin-bottom: 17px;
  }
}

#_capacitor-camera-input,
._capacitor-camera-input {
  display: none;
}

@media screen and (max-width: 991px) {
  curbnturf-photo-display {
    .save-icon-wrapper {
      left: auto;
      right: 0px;
      bottom: 20px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-top: 0px;
      padding-right: 0px;
      border-radius: 6px 0px 0px 6px;
      &.listing {
        bottom: 20px;
      }
    }
  }

  .listing-content-section {
    overflow: visible;
  }
}

/*---------------------------------------- Extra CSS -------------------------*/
.grid-photo {
  background-image: url('/img/wf/AdobeStock_296386260_1080x691-p-800.jpeg');
  &.traveled {
    background-image: url('/img/wf/tim-gouw-JasperNationalPark-Tim-Gouw-p-800.jpeg');
  }
  &.planning {
    background-image: url('/img/wf/airstream-inc-pcKqDMeWbD8-unsplash-p-800.jpeg');
  }
  &.boondocking {
    background-image: url('/img/wf/aurelien-designatic-782561-unsplash_4k-p-1600.jpeg');
  }
  &.adventure {
    background-image: url('/img/wf/AdobeStock_282260949_1920x1280-p-1080.jpeg');
  }
  &.escape {
    background-image: url('/img/wf/airstream-inc-kiCpH9d4SCc-unsplash_1080x810-p-800.jpeg');
  }
  &.best {
    background-image: url('/img/wf/ludovic-fremondiere-386791-unsplash_1920x1439-p-800.jpeg');
  }
  &.works {
    background-image: url('/img/wf/joel-holland-RwxJtL-WshE-unsplash_CNT-p-800.jpeg');
  }
  &.app {
    background-image: url('/img/wf/CurbNTurf-App-Phone-mockup-4k-p-1600.png');
  }
  &.benefits {
    background-image: url('/img/wf/hari-nandakumar-1440558-unsplash-4k-p-800.jpeg');
  }
  &.problem-more {
    background-image: url('/img/wf/rv-talk-Fk5cBS4BUvQ-unsplash-p-2000.jpeg');
  }
  &.boondocking-more {
    background-image: url('/img/wf/chris-holder-658988-unsplash-p-800.jpeg');
  }
  &.started {
    background-image: url('/img/wf/AdobeStock_306216284_1920x1080-p-1600.jpeg');
  }
  &.details {
    background-image: url('/img/wf/kent-rebman-Hau9TnHS130-unsplash-p-1080.jpeg');
  }
  &.facts {
    background-image: url('/img/wf/AdobeStock_207612528_1920x1280-p-800.jpeg');
  }
  &.grow {
    background-image: url('/img/wf/jessica-rockowitz-1145859-unsplash_1920x1478-p-800.jpeg');
  }
  &.success {
    background-image: url('/img/wf/airstream-inc-pcKqDMeWbD8-unsplash-p-800.jpeg');
  }
  &.money {
    background-image: url('/img/wf/AdobeStock_164016813_1920x1280-p-800.jpeg');
  }
}

.hero-section {
  &._1 {
    background-image: url('/img/wf/AdobeStock_368005450.jpg');
  }

  &._2 {
    background-image: url('/img/wf/AdobeStock_361876832.jpg');
  }

  &._3 {
    background-image: url('/img/wf/AdobeStock_186721503.jpg');
  }

  &._4 {
    background-image: url('/img/wf/AdobeStock_119263642.jpg');
  }

  &._5 {
    background-image: url('/img/wf/AdobeStock_118652942.jpeg');
  }
}

@media (max-width: 3200px) {
  .large-device {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-3200.jpeg');
  }

  .hero-background {
    background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-3200.jpeg');
  }

  .secondary-hero-wrapper {
    background-image: url('/img/wf/willian-justen-de-vasconcellos-502743-unsplash-4k-p-3200.jpeg');
    &.host {
      background-image: url('/img/wf/benjamin-davies-260884-unsplash-4k-p-3200.jpeg');
    }
    &.host-more {
      background-image: url('/img/wf/frances-gunn-39236-unsplash-4k-p-3200.jpeg');
    }
    &.grow {
      background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-3200.jpeg');
    }
  }

  .small-device-copy.money {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-3200.jpeg');
  }
}

@media (max-width: 2600px) {
  .large-device {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-2600.jpeg');
  }

  .hero-background {
    background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-2600.jpeg');
  }

  .secondary-hero-wrapper {
    background-image: url('/img/wf/willian-justen-de-vasconcellos-502743-unsplash-4k-p-2600.jpeg');
    &.host {
      background-image: url('/img/wf/benjamin-davies-260884-unsplash-4k-p-2600.jpeg');
    }
    &.host-more {
      background-image: url('/img/wf/frances-gunn-39236-unsplash-4k-p-2600.jpeg');
    }
    &.grow {
      background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-2600.jpeg');
    }
  }

  .small-device-copy.money {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-2600.jpeg');
  }
}

@media (max-width: 2000px) {
  .large-device {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-2000.jpeg');
  }

  .hero-background {
    background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-2000.jpeg');
  }

  .secondary-hero-wrapper {
    background-image: url('/img/wf/willian-justen-de-vasconcellos-502743-unsplash-4k-p-2000.jpeg');
    &.host {
      background-image: url('/img/wf/benjamin-davies-260884-unsplash-4k-p-2000.jpeg');
    }
    &.host-more {
      background-image: url('/img/wf/frances-gunn-39236-unsplash-4k-p-2000.jpeg');
    }
    &.grow {
      background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-2000.jpeg');
    }
  }

  .small-device-copy.money {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-2000.jpeg');
  }
}

@media (max-width: 1600px) {
  .large-device {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-1600.jpeg');
  }

  .hero-background {
    background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-1600.jpeg');
  }

  .secondary-hero-wrapper {
    background-image: url('/img/wf/willian-justen-de-vasconcellos-502743-unsplash-4k-p-1600.jpeg');
    &.host {
      background-image: url('/img/wf/benjamin-davies-260884-unsplash-4k-p-1600.jpeg');
    }
    &.host-more {
      background-image: url('/img/wf/frances-gunn-39236-unsplash-4k-p-1600.jpeg');
    }
    &.grow {
      background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-1600.jpeg');
    }
  }

  .small-device-copy.money {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-1600.jpeg');
  }

  .hero-section {
    &._1 {
      background-image: url('/img/wf/AdobeStock_368005450-p-1600.jpeg');
    }

    &._2 {
      background-image: url('/img/wf/AdobeStock_361876832-p-1600.jpeg');
    }

    &._3 {
      background-image: url('/img/wf/AdobeStock_186721503-p-1600.jpeg');
    }

    &._4 {
      background-image: url('/img/wf/AdobeStock_119263642-p-1600.jpeg');
    }

    &._5 {
      background-image: url('/img/wf/AdobeStock_118652942-p-1600.jpeg');
    }
  }
}

@media (max-width: 1200px) {
  .grid-photo {
    background-image: url('/img/wf/AdobeStock_296386260_1080x691-p-500.jpeg');
  }
}

@media (max-width: 1080px) {
  .large-device {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-1080.jpeg');
  }

  .secondary-hero-wrapper {
    background-image: url('/img/wf/willian-justen-de-vasconcellos-502743-unsplash-4k-p-1080.jpeg');
    &.host {
      background-image: url('/img/wf/benjamin-davies-260884-unsplash-4k-p-1080.jpeg');
    }
    &.host-more {
      background-image: url('/img/wf/frances-gunn-39236-unsplash-4k-p-1080.jpeg');
    }
  }

  .small-device-copy.money {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash-p-1080.jpeg');
  }

  .hero-section {
    &._1 {
      background-image: url('/img/wf/AdobeStock_368005450-p-1080.jpeg');
    }

    &._2 {
      background-image: url('/img/wf/AdobeStock_361876832-p-1080.jpeg');
    }

    &._3 {
      background-image: url('/img/wf/AdobeStock_186721503-p-1080.jpeg');
    }

    &._4 {
      background-image: url('/img/wf/AdobeStock_119263642-p-1080.jpeg');
    }

    &._5 {
      background-image: url('/img/wf/AdobeStock_118652942-p-1080.jpeg');
    }
  }
}

@media (max-width: 991px) {
  .grid-photo {
    background-image: url('/img/wf/AdobeStock_296386260_1080x691.jpg');
    &.traveled {
      background-image: url('/img/wf/tim-gouw-JasperNationalPark-Tim-Gouw-p-1080.jpeg');
    }
    &.planning {
      background-image: url('/img/wf/airstream-inc-pcKqDMeWbD8-unsplash-p-1080.jpeg');
    }
    &.boondocking {
      background-image: url('/img/wf/aurelien-designatic-782561-unsplash_4k-p-1600.jpeg');
    }
    &.adventure {
      background-image: url('/img/wf/AdobeStock_282260949_1920x1280-p-1080.jpeg');
    }
    &.escape {
      background-image: url('/img/wf/airstream-inc-kiCpH9d4SCc-unsplash_1080x810.jpg');
    }
    &.best {
      background-image: url('/img/wf/ludovic-fremondiere-386791-unsplash_1920x1439-p-1080.jpeg');
    }
    &.works {
      background-image: url('/img/wf/joel-holland-RwxJtL-WshE-unsplash_CNT-p-1600.jpeg');
    }
    &.app {
      background-image: url('/img/wf/CurbNTurf-App-Phone-mockup-4k-p-1600.png');
    }
    &.benefits {
      background-image: url('/img/wf/hari-nandakumar-1440558-unsplash-4k-p-1080.jpeg');
    }
    &.problem-more {
      background-image: url('/img/wf/rv-talk-Fk5cBS4BUvQ-unsplash-p-2000.jpeg');
    }
    &.boondocking-more {
      background-image: url('/img/wf/chris-holder-658988-unsplash-p-1080.jpeg');
    }
    &.started {
      background-image: url('/img/wf/AdobeStock_306216284_1920x1080-p-1600.jpeg');
    }
    &.details {
      background-image: url('/img/wf/kent-rebman-Hau9TnHS130-unsplash-p-1080.jpeg');
    }
    &.facts {
      background-image: url('/img/wf/AdobeStock_207612528_1920x1280-p-1080.jpeg');
    }
    &.grow {
      background-image: url('/img/wf/jessica-rockowitz-1145859-unsplash_1920x1478-p-1080.jpeg');
    }
    &.success {
      background-image: url('/img/wf/airstream-inc-pcKqDMeWbD8-unsplash-p-1080.jpeg');
    }
    &.money {
      background-image: url('/img/wf/AdobeStock_164016813_1920x1280-p-1080.jpeg');
    }
  }
}

@media (max-width: 800px) {
  .hero-background {
    background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-800.jpeg');
  }

  .grid-photo {
    background-image: url('/img/wf/AdobeStock_296386260_1080x691-p-800.jpeg');
    &.traveled {
      background-image: url('/img/wf/tim-gouw-JasperNationalPark-Tim-Gouw-p-800.jpeg');
    }
    &.planning {
      background-image: url('/img/wf/airstream-inc-pcKqDMeWbD8-unsplash-p-800.jpeg');
    }
    &.boondocking {
      background-image: url('/img/wf/aurelien-designatic-782561-unsplash_4k-p-1600.jpeg');
    }
    &.escape {
      background-image: url('/img/wf/airstream-inc-kiCpH9d4SCc-unsplash_1080x810-p-800.jpeg');
    }
    &.best {
      background-image: url('/img/wf/ludovic-fremondiere-386791-unsplash_1920x1439-p-800.jpeg');
    }
    &.works {
      background-image: url('/img/wf/joel-holland-RwxJtL-WshE-unsplash_CNT-p-800.jpeg');
    }
    &.app {
      background-image: url('/img/wf/CurbNTurf-App-Phone-mockup-4k-p-1600.png');
    }
    &.benefits {
      background-image: url('/img/wf/hari-nandakumar-1440558-unsplash-4k-p-800.jpeg');
    }
    &.boondocking-more {
      background-image: url('/img/wf/chris-holder-658988-unsplash-p-800.jpeg');
    }
    &.started {
      background-image: url('/img/wf/AdobeStock_306216284_1920x1080-p-1600.jpeg');
    }
    &.facts {
      background-image: url('/img/wf/AdobeStock_207612528_1920x1280-p-800.jpeg');
    }
    &.grow {
      background-image: url('/img/wf/jessica-rockowitz-1145859-unsplash_1920x1478-p-800.jpeg');
    }
    &.success {
      background-image: url('/img/wf/airstream-inc-pcKqDMeWbD8-unsplash-p-800.jpeg');
    }
    &.money {
      background-image: url('/img/wf/AdobeStock_164016813_1920x1280-p-800.jpeg');
    }
  }

  .secondary-hero-wrapper {
    background-image: url('/img/wf/willian-justen-de-vasconcellos-502743-unsplash-4k-p-800.jpeg');
    &.host {
      background-image: url('/img/wf/benjamin-davies-260884-unsplash-4k-p-800.jpeg');
    }
    &.host-more {
      background-image: url('/img/wf/frances-gunn-39236-unsplash-4k-p-800.jpeg');
    }
    &.grow {
      background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-800.jpeg');
    }
  }

  .hero-section {
    &._1 {
      background-image: url('/img/wf/AdobeStock_368005450-p-800.jpeg');
    }

    &._2 {
      background-image: url('/img/wf/AdobeStock_361876832-p-800.jpeg');
    }

    &._3 {
      background-image: url('/img/wf/AdobeStock_186721503-p-1080.jpeg');
    }

    &._4 {
      background-image: url('/img/wf/AdobeStock_119263642-p-1080.jpeg');
    }

    &._5 {
      background-image: url('/img/wf/AdobeStock_118652942-p-1080.jpeg');
    }
  }
}

@media (max-width: 500px) {
  .hero-background {
    background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-500.jpeg');
  }

  .secondary-hero-wrapper {
    background-image: url('/img/wf/willian-justen-de-vasconcellos-502743-unsplash-4k-p-500.jpeg');
    &.host {
      background-image: url('/img/wf/benjamin-davies-260884-unsplash-4k-p-500.jpeg');
    }
    &.host-more {
      background-image: url('/img/wf/frances-gunn-39236-unsplash-4k-p-500.jpeg');
    }
    &.grow {
      background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k-p-500.jpeg');
    }
  }

  .hero-section {
    &._1 {
      background-image: url('/img/wf/AdobeStock_368005450-p-500.jpeg');
    }

    &._2 {
      background-image: url('/img/wf/AdobeStock_361876832-p-500.jpeg');
    }

    &._3 {
      background-image: url('/img/wf/AdobeStock_186721503-p-500.jpeg');
    }

    &._4 {
      background-image: url('/img/wf/AdobeStock_119263642-p-500.jpeg');
    }

    &._5 {
      background-image: url('/img/wf/AdobeStock_118652942-p-1080.jpeg');
    }
  }
}
