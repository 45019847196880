$primary: #e3530d;
$secondary: #454545;
$success: #007bff;
$info: #9cb29f;
$warning: #ffc107;
$danger: #ff0000;
$light: #d8d6d6;
$dark: #343a40;
$black: #000;
$white: #fff;

$theme-color-interval: 8%;

/* custom */
:root {
  --black: #000;
  --danger: #ff0000;
  --dark: #343a40;
  --dark-25: rgba(52, 58, 64, 0.25);
  --dark-50: rgba(52, 58, 64, 0.5);
  --gray-100: #f6f8f9;
  --gray-300: #d8d6d6;
  --green: #465d50;
  --green-50: rgba(70, 93, 80, 0.5);
  --green-80: rgba(70, 93, 80, 0.8);
  --green-light: rgba(156, 178, 159, 1);
  --info: #9cb29f;
  --light: #d8d6d6;
  --primary: #e3530d;
  --primary-25: rgba(243, 83, 13, 0.25);
  --primary-50: rgba(243, 83, 13, 0.5);
  --secondary: #454545;
  --secondary-50: rgba(52, 58, 64, 0.5);
  --secondary-80: rgba(52, 58, 64, 0.8);
  --secondary-900: #212722;
  --secondary-950: #121c1e;
  --success: #007bff;
  --warning: #ffc107;
  --white: #fff;
  --white-50: rgba(255, 255, 255, 0.5);
}

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);
